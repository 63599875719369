<template>
  <v-row class="my-2 frmConfigEmpresa">
    <v-col cols="12">
      <center>
        <p class="text-azul">1. CREA TU EMPRESA</p>
      </center>
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Nombre de la empresa *"
        hide-details
        dense
        v-model="$store.state.configEmpresa.datoEmpresa.trade_name"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-file-input
        outlined
        accept="image/png, image/jpeg, image/bmp"
        placeholder="Subir tu logo te ayudará a personalizar tus documentos"
        append-icon="mdi-camera"
        hide-details
        prepend-icon=""
        dense
        v-model="$store.state.configEmpresa.datoEmpresa.img"
        @change="cargarLogo()"
      ></v-file-input>
    </v-col>
    <v-col cols="12">
      <CboPais
        @paisSeleccionado="
          $store.state.configEmpresa.datoEmpresa.id_pais = $event
        "
        :outlined="true"
        :dense="true"
        :hideDetails="true"
        :newId="$store.state.configEmpresa.datoEmpresa.id_pais"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        label="Dirección fiscal (Opcional)"
        hide-details
        dense
        v-model="$store.state.configEmpresa.datoEmpresa.address"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <p>
        <b>Administrador del Sistema</b>
      </p>
      <p>
        <b>Nombre:</b> {{ $store.state.configEmpresa.dataUsuario.names }}
        {{ $store.state.configEmpresa.dataUsuario.surname }}
        {{ $store.state.configEmpresa.dataUsuario.second_surname }}
      </p>
      <p><b>Teléfono:</b> {{ $store.state.configEmpresa.dataUsuario.phone }}</p>
      <p><b>Email:</b> {{ $store.state.configEmpresa.dataUsuario.email }}</p>
    </v-col>
    <v-col cols="12" style="align-items: center; text-align: center">
      <v-btn class="mx-10" color="#7E7E7E" dark @click="$emit('omitir')">
        Omitir
      </v-btn>
      <v-btn
        class="mx-10"
        color="#4256BE"
        dark
        @click="$emit('GuardarDatosEmpresa')"
      >
        Guardar y Continuar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import CboPais from "@/components/comun/cboPais.vue";
export default {
  components: {
    CboPais,
  },
  data() {
    return { datosEmpresa: { id_pais: null } };
  },
  methods: {
    ...mapActions(["actualizarDatoEmpresa", "_uploadFile"]),
    async cargarLogo() {
      await this._uploadFile(this.$store.state.configEmpresa.datoEmpresa.img);
      this.$store.state.configEmpresa.datoEmpresa.id_logo =
        this.$store.state.files.datosPath.insertid;
      this.$store.state.configEmpresa.datoEmpresa.logo =
        this.$store.state.files.datosPath.ruta;
      // console.log(this.$store.state.configEmpresa.datoEmpresa);
    },
  },
  mounted() {
    this.$store.state.configEmpresa.datoEmpresa = JSON.parse(
      localStorage.getItem("dataBranch")
    )[0];
    this.$store.state.configEmpresa.dataUsuario = JSON.parse(
      localStorage.getItem("dataUser")
    )[0];
  },
};
</script>

<style scoped>
.frmConfigEmpresa {
  margin: 0 30% !important;
}
.text-azul {
  /* background: red; */
  color: #4256be !important;
  font-size: 1.5rem;
  font-weight: bold;
}
@media (max-width: 1000px) {
  .frmConfigEmpresa {
    margin: 0 2% !important;
  }
}
</style>
